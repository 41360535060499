import React from 'react'
import { Spinner } from 'reactstrap'
import './index.scss'

const Loader = () => (
  <div className='loader'>
    <Spinner />
  </div>
)

export default Loader
